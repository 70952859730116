import { DynamicComponentManifest } from '@page2flip/dcl';

export const dynamicComponents: DynamicComponentManifest[] = [
  {
    componentId: 'desktop-controls',
    path: 'p2f-desktop-controls',
    loadChildren: () => import('./controls/desktop-controls/desktop-controls.module').then(m => m.DesktopControlsModule)
  },
  {
    componentId: 'touch-controls',
    path: 'p2f-touch-controls',
    loadChildren: () => import('./controls/touch-controls/touch-controls.module').then(m => m.TouchControlsModule)
  },
  {
    componentId: 'feature-config',
    path: 'p2f-config',
    loadChildren: () => import('./features/dialogs/config/config.module').then(m => m.ConfigModule)
  },
  {
    componentId: 'feature-download',
    path: 'p2f-download',
    loadChildren: () => import('./features/dialogs/download/download.module').then(m => m.DownloadModule)
  },
  {
    componentId: 'feature-feedback',
    path: 'p2f-feedback',
    loadChildren: () => import('./features/dialogs/feedback/feedback.module').then(m => m.FeedbackModule)
  },
  {
    componentId: 'feature-help',
    path: 'p2f-help',
    loadChildren: () => import('./features/dialogs/help/help.module').then(m => m.HelpModule)
  },
  {
    componentId: 'feature-hotspot',
    path: 'p2f-hotspot',
    loadChildren: () => import('./features/dialogs/hotspot/hotspot.module').then(m => m.HotspotModule)
  },
  {
    componentId: 'print-preview',
    path: 'p2f-print-preview',
    loadChildren: () => import('./features/dialogs/print-preview/print-preview.module').then(m => m.PrintPreviewModule)
  },
  {
    componentId: 'feature-info',
    path: 'p2f-info',
    loadChildren: () => import('./features/dialogs/info/info.module').then(m => m.InfoModule)
  },
  {
    componentId: 'feature-legal',
    path: 'p2f-legal',
    loadChildren: () => import('./features/dialogs/legal/legal.module').then(m => m.LegalModule)
  },
  {
    componentId: 'feature-note',
    path: 'p2f-note',
    loadChildren: () => import('./features/dialogs/note/note.module').then(m => m.NoteModule)
  },
  {
    componentId: 'feature-share',
    path: 'p2f-share',
    loadChildren: () => import('./features/dialogs/share/share.module').then(m => m.ShareModule)
  },
  {
    componentId: 'feature-pageComparison',
    path: 'p2f-page-comparison',
    loadChildren: () => import('./features/dialogs/page-comparison/page-comparison.module').then(m => m.PageComparisonModule)
  },
  {
    componentId: 'feature-pageOverview',
    path: 'p2f-page-overview',
    loadChildren: () => import('./features/side-panels/page-overview/page-overview.module').then(m => m.PageOverviewModule)
  },
  {
    componentId: 'feature-page-select',
    path: 'p2f-page-select',
    loadChildren: () => import('./features/side-panels/page-select/page-select.module').then(m => m.PageSelectModule)
  },
  {
    componentId: 'feature-search',
    path: 'p2f-search',
    loadChildren: () => import('./features/side-panels/search/search.module').then(m => m.SearchModule)
  },
  {
    componentId: 'feature-shoppingCart',
    path: 'p2f-shopping-cart',
    loadChildren: () => import('./features/side-panels/shopping-cart/shopping-cart.module').then(m => m.ShoppingCartModule)
  },
  {
    componentId: 'feature-tableOfContents',
    path: 'p2f-table-of-contents',
    loadChildren: () => import('./features/side-panels/table-of-contents/table-of-contents.module').then(m => m.TableOfContentsModule)
  },
  {
    componentId: 'feature-watchList',
    path: 'p2f-watch-list',
    loadChildren: () => import('./features/side-panels/watch-list/watch-list.module').then(m => m.WatchListModule)
  },
  {
    componentId: 'feature-pageMarkers',
    path: 'p2f-page-markers',
    loadChildren: () => import('./features/side-panels/page-markers/page-markers.module').then(m => m.PageMarkersModule)
  },
  {
    componentId: 'feature-gallery',
    path: 'p2f-gallery',
    loadChildren: () => import('./features/dialogs/gallery/gallery.module').then(m => m.GalleryModule)
  },
];
