<p2f-loader *ngIf="loading" [message]="loaderMessage"></p2f-loader>

<section #touchControls></section>
<section #desktopControls></section>

<p2f-zoom>
  <div id="p2f-content" class="content">
    <p2f-document (swipeLeft)="onSwipeLeft()"
                  (swipeRight)="onSwipeRight()">
    </p2f-document>
    <p2f-hotspot-layer [pageNumbers]="visiblePages"
                       (swipeLeft)="onSwipeLeft()"
                       (swipeRight)="onSwipeRight()">
    </p2f-hotspot-layer>
  </div>
</p2f-zoom>

<p2f-side-panel *ngIf="sidePanelVisible"
                [@sidePanelVisibility]="{value: 'transition', params: {'transform': [sidePanelTransform]}}"
                [@sidePanelWidth]="sidePanelWidth"
                [horizontalPosition]="layout.sidePanelPosition"
                [verticalPosition]="layout.toolbarPosition"
                [class.transparent]="transparency"
                [class.touch]="touchMode"
                class="mat-elevation-z16"></p2f-side-panel>

<div *ngIf="debugMode" class="stats" [style.bottom]="statsBottom">
  <div class="progress-wrapper">
  </div>
</div>
